import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import Downloadables from '../../elements/downloadables';

class Amblyopia extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h3>Common Eye & Vision Conditions</h3>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/accommodativevergencedysfunction">Common Eye & Vision Conditions</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Amblyopia</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Amblyopia</h2>
											<p className="nse-paragraph mb-0">Often referred to as "lazy eye," is a vision disorder that occurs when the visual pathways in the brain do not develop properly. It typically affects one eye, which is often referred to as the "lazy eye," while the other eye has normal vision. Amblyopia usually begins in infancy or early childhood and can lead to reduced vision in the affected eye if not detected and treated early.</p>											
											<div className='heading-bx'>
												<h6 className="title-ext text-primary">Key characteristics of Amblyopia</h6>
											</div>
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Reduced Visual Acuity</h4>
											<p className="nse-paragraph mb=1">The most common feature of amblyopia is a significant reduction in visual acuity (clarity of vision) in one eye. This reduction in vision cannot be corrected with glasses or contact lenses, and it is not due to any structural abnormalities of the eye.</p>

											<h4 className="title mb-10 mt-10">Binocular Vision Issues</h4>
											<p className="nse-paragraph mb=1">Amblyopia can disrupt binocular vision, which is the ability of both eyes to work together. This can lead to problems with depth perception and visual coordination.</p>
											
											<h4 className="title mb-10 mt-10">Causes</h4>
											<p className="nse-paragraph mb=1">: Amblyopia can be caused by various factors, including strabismus (eye misalignment), where one eye turns inward (esotropia) or outward (exotropia); anisometropia, which is a significant difference in refractive error (such as one eye being much more nearsighted or farsighted than the other); or deprivation amblyopia, where there is a physical obstruction or visual deprivation in one eye (e.g., due to a congenital cataract).</p>
											
											<h4 className="title mb-10 mt-10">Onset in Childhood</h4>
											<p className="nse-paragraph mb=1">Amblyopia typically develops during childhood when the visual system is still developing and can be more easily treated. Early detection and intervention are crucial.</p>

											<h4 className="title mb-10 mt-10">Treatment</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Corrective Lenses: Prescribing glasses or contact lenses to correct significant refractive errors.</li>
													<li className='nse-list-group-item'>Vision Therapy: Specialized exercises and activities to improve visual skills and coordination.The effectiveness of treatment depends on the individual's age, the underlying cause of amblyopia, and the willingness to comply with the treatment plan. Amblyopia is a treatable condition, and when diagnosed and managed early, many individuals can achieve improved vision and develop better visual function in the affected eye.</li>
													<li className='nse-list-group-item'>Controlled Deprivation: Reducing the visual stimulation of the stronger eye using graded frosted lens to encourage the weaker eye to work and develop better visual acuity.</li>
													<li className='nse-list-group-item'>Atropine Eye Drops: Using atropine drops in the stronger eye to temporarily blur the vision, forcing the weaker eye to work.</li>
												</ul>
											</div>											
										</div>
										<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Symptoms</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 1</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Treatment</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 2</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/accommodativevergencedysfunction"><span>Accommodative Vergence Dysfunction</span><i className="fa fa-angle-right"></i></Link></li>
												<li className='active'><Link to="/amblyopia"><span>Amblyopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/astigmatism"><span>Astigmatism</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Blepharitis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Cataracts</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Colour Blindness</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Conjunctivitis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Convergence Insufficiency</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Diabetic Retinopathy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Diplopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Dry Eyes or Watery Eyes</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Emmetropia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Floaters</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Glaucoma</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Hordeolum (Stye)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Hypermetropia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Iritis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Keratoconus</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Keratitis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Macular Degeneration</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Maculopathy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Meibomian gland Dysfunction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Myopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Pinguecula</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Presbyopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Ptosis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Pterygium</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Retinal Detachment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Scleritis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Strabismus</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Superficial Punctate Keratitis (SPK) </span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Trichiasis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Traumatic Brain Injury (TBI)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Uveitis</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default Amblyopia;