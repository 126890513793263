import axios from "./axios";

class BookingAPIService {
    

    async getList(){
        try{
            return await axios.get("/api/v1/bookingrecord",{
                headers: { "Content-Type": "application/json"},
            }).then(res => res);
        }catch(err){
            console.error(err);
            throw err;
        }
    }

    async getStatus(id){
        try{
            return await axios.get("/api/v1/bookingrecord/status/"+id,{
                headers: { "Content-Type": "application/json"},
            }).then(res => res);
        }catch(err){
            console.error(err);
            throw err;
        }
    }

    async submitBooking(data){
        try{
            return await axios.post("/api/v1/bookingrecord/create",{
                ...data
              },{
                headers: { "Content-Type": "application/json"},
            }).then(res => res);
        }catch(err){
            console.error(err);
            throw err;
        }
    }

    

    async checkDate(bookDate,bookTime,branch){
        try{
            return await axios.post("/api/v1/bookingrecord/checkdate",{
                id:0,
                bookingdate:bookDate,
                bookingtime:bookTime,
                branch:branch
              },{
                headers: { "Content-Type": "application/json"},
            }).then(res => res);
        }catch(err){
            console.error(err);
            throw err;
        }
    }

    async checkTime(bookDate,bookTime,branch){
        try{
            return await axios.post("/api/v1/bookingrecord/checktime",{
                id:0,
                bookingdate:bookDate,
                bookingtime:bookTime,
                branch:branch
              },{
                headers: { "Content-Type": "application/json"},
            }).then(res => res);
        }catch(err){
            console.error(err);
            throw err;
        }
    }

    
    
}

export default BookingAPIService