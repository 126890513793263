import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/vpa.jpg";
import Downloadables from '../../elements/downloadables';

class VisualPA extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Services</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/services">Services</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Visual Performance Assessment (VPA)</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Visual Performance Assessment (VPA)</h2>
											<p className="nse-paragraph mb-0">At Nakpil Eye Clinic, we believe that optimal visual performance is the cornerstone of a vibrant and successful life. Our Visual Performance Assessment (VPA) is meticulously designed to evaluate and enhance the efficiency and precision of your visual system. Whether you're an athlete aiming for peak sports performance, a professional seeking to reduce eye strain, or someone wanting to unlock the full potential of your visual abilities, our comprehensive assessments provide valuable insights and tailored solutions.</p>											
											<p className="nse-paragraph mb-0">Our Visual Performance Assessment goes beyond routine eye exams. We utilize state-of-the-art diagnostic tools and a holistic approach to evaluate various aspects of visual function. Our team of experienced optometrists and visual performance specialists collaborates to provide a detailed analysis, offering personalized recommendations to optimize your visual performance.</p>			
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Key Assessment Areas</h4>											
											<h6 className="text-info mb-10 mt-30" >1. Visual Acuity and Refraction</h6>
											<p className="nse-paragraph mb-10">Precise measurement of visual acuity and refractive error to ensure optimal clarity and focus.</p>

											<h6 className="text-info mb-10 mt-30">2. Eye Tracking and Coordination</h6>
											<p className="nse-paragraph mb-10">Evaluation of eye movement coordination, tracking, and convergence to assess overall eye teaming skills.</p>

											
											<h6 className="text-info mb-10 mt-30">3. Depth Perception</h6>
											<p className="nse-paragraph mb-10">Assessment of the ability to perceive depth accurately, crucial for activities such as sports and driving.</p>

											
											<h6 className="text-info mb-10 mt-30">4. Peripheral Vision</h6>
											<p className="nse-paragraph mb-10">Examination of peripheral vision to identify any limitations or irregularities.</p>
											
											<h6 className="text-info mb-10 mt-30">5. Visual Processing Speed</h6>
											<p className="nse-paragraph mb-10">Analysis of how quickly and accurately visual information is processed, a vital aspect for cognitive and academic performance.</p>

											<h4 className="title mb-30 mt-10">Why Choose Nakpil Eye Clinic for Visual Performance Assessment?</h4>
											<div className="ml-30">	
												<ul>
												<li className='nse-list-group-item'>Specialized Expertise - Our team comprises skilled optometrists trained by Dr. John Nakpil, and clinical technicians with a focus on enhancing visual function.</li>
													<li className='nse-list-group-item'>Cutting-Edge Technology - We employ advanced diagnostic tools and technology to provide accurate and comprehensive assessments.</li>
													<li className='nse-list-group-item'>Customized Recommendations - Based on the assessment results, we develop personalized recommendations and interventions to address specific visual performance needs.</li>
													<li className='nse-list-group-item'>Holistic Vision Care - We understand that visual performance is interconnected with overall well-being. Our approach considers lifestyle factors, occupational demands, and individual goals.</li>
												</ul>
											</div>
											

											<p className="nse-paragraph mb-0 mt-30">Invest in Your Visual Excellence Today! Unleash the power of your vision with Nakpil Eye Clinic’s Visual Performance Assessment. Whether you're seeking improvements for sports, work, or daily activities, our assessments pave the way for a clearer and more efficient visual experience.</p>

											<p className="mb-0 mt-10">Elevate your visual potential with Nakpil Eye Clinic – Your Eye and Vision Specialist.</p>

											
										</div>
										{
											/*
											<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Question 1</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 1</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Question 2</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 2</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
											*/
										}
										
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">										
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Examination Packages</h6>
												</div>
												<li><Link to="/standardeyeexam"><span>Standard Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/premiumeyeexam"><span>Premium Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pediatriceyeexam"><span>Pediatric Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeexam"><span>Executive Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeplusexam"><span>Executive Plus Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/corporateeyeexam"><span>Corporate Eye Care Program</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/followupeyeexam"><span>Follow-up Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<div className="widget">
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Other Services</h6>
												</div>
												<li><Link to="/biometry"><span>Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cycloplegicrefraction"><span>Cycloplegic Eyedrops</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/foreignbdr"><span>Foreign Bodies Removal</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundusairdoc"><span>Fundus Scan with AirDoc</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/funduskanghua"><span>Fundus Scan with Kanghua</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/iopassessment"><span>Intra Ocular Pressure Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharatest"><span>Ishihara Color Vision Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharafanswort"><span>Ishihara with Farnsworth</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratology"><span>OrthoKeratology Lens</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratologylens"><span>OrthoKeratology Lens Fitting</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry"><span>Perimetry / Visual Field Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/topography"><span>Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visiontherapy"><span>Vision Therapy</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/visualpa"><span>Visual Performance Assessment</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default VisualPA;