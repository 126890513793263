import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/funduskanghua.jpg";
import Downloadables from '../../elements/downloadables';

class ForeignBodiesRemoval extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Services</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/services">Services</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Foreign Bodies Removal</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Foreign Bodies Removal</h2>
											<p className="nse-paragraph mb-30">Accidents happen, and when a foreign body finds its way into your eye, prompt and expert removal is essential. Our Foreign Body Removal service is designed to provide swift relief, ensuring your eyes stay clear, comfortable, and healthy.</p>											
											<div className='heading-bx mt-5 mb-5'>
												<h6 className="title-ext text-primary">What to Expect</h6>
											</div>
											<p className="nse-paragraph mb-0">Foreign bodies in the eye can cause discomfort, irritation, and potential damage if not addressed promptly. Our Foreign Body Removal service is a quick and specialized procedure conducted by our experienced eye care professionals. We aim to alleviate your discomfort and minimize any potential risks associated with foreign objects lodged in the eye.</p>			
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Key Aspects of Foreign Body Removal</h4>											
											<h6 className="text-info mb-10 mt-30" >1. Prompt Intervention</h6>
											<p className="nse-paragraph mb-10">We prioritize immediate action to address the presence of foreign bodies, preventing further irritation or injury to the eye.</p>

											<h6 className="text-info mb-10 mt-30">2. Specialized Tools and Techniques</h6>
											<p className="nse-paragraph mb-10">Our eye care professionals use specialized tools and techniques to safely and effectively remove foreign bodies, ensuring minimal discomfort for the patient.</p>

											
											<h6 className="text-info mb-10 mt-30">3. Comprehensive Eye Examination</h6>
											<p className="nse-paragraph mb-10">Following the removal procedure, we conduct a thorough examination to assess any potential damage or irritation caused by the foreign body.</p>

											
											<h6 className="text-info mb-10 mt-30">4. Patient Education</h6>
											<p className="nse-paragraph mb-10">We take the time to educate our patients on preventive measures and safety practices to minimize the risk of future foreign body incidents.</p>

											

											<p className="nse-paragraph mb-0 mt-30">For prompt and expert foreign body removal, trust Nakpil Eye Clinic. Let us provide the swift relief you need, ensuring your eyes remain clear, comfortable, and healthy.</p>

											
										</div>
										{
											/*
											<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Question 1</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 1</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Question 2</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 2</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
											*/
										}
										
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">										
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Examination Packages</h6>
												</div>
												<li><Link to="/standardeyeexam"><span>Standard Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/premiumeyeexam"><span>Premium Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pediatriceyeexam"><span>Pediatric Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeexam"><span>Executive Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/executiveeyeplusexam"><span>Executive Plus Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/corporateeyeexam"><span>Corporate Eye Care Program</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/followupeyeexam"><span>Follow-up Eye Examination</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<div className="widget">
											<ul className="service-menu">
												<div className='heading-bx'>
													<h6 className="title-ext text-primary">Other Services</h6>
												</div>
												<li><Link to="/biometry"><span>Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cycloplegicrefraction"><span>Cycloplegic Eyedrops</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/foreignbdr"><span>Foreign Bodies Removal</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundusairdoc"><span>Fundus Scan with AirDoc</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/funduskanghua"><span>Fundus Scan with Kanghua</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/iopassessment"><span>Intra Ocular Pressure Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharatest"><span>Ishihara Color Vision Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ishiharafanswort"><span>Ishihara with Farnsworth</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratology"><span>OrthoKeratology Lens</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/orthokeratologylens"><span>OrthoKeratology Lens Fitting</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry"><span>Perimetry / Visual Field Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/topography"><span>Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visiontherapy"><span>Vision Therapy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/visualpa"><span>Visual Performance Assessment</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default ForeignBodiesRemoval;