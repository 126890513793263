import axios from "./axios";

class EmailAPIService {
    

    

    async submitEmail(data){
        try{
            return await axios.post("/emailus",{
                ...data
              },{
                headers: { "Content-Type": "application/json"},
            }).then(res => res);
        }catch(err){
            console.error(err);
            throw err;
        }
    }

    
    
}

export default EmailAPIService