import axios from "axios";

const BASE_URL = "https://rsapi1.nakpileyeclinic.com";//,https://rsapi1.nakpileyeclinic.com
const BASE_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Credentials':true
};

export default axios.create({
    baseURL: BASE_URL,
    headers: BASE_HEADERS,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: BASE_HEADERS,
});
