import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/tests/BioMicroscopy.jpg";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";
import Downloadables from '../../elements/downloadables';

class BioMicroscopyDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Eye Tests</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/">Eye Tests</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Bio Microscopy</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									{/*									
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									*/
									}
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Bio-Microscopy</h2>
											<p className="nse-paragraph mb-0">Bio-microscope, commonly known as a slit lamp, is a specialized ophthalmic instrument that we use at Nakpil Eye Clinic 
																to examine the anterior segment of the eye in detail. It provides high magnification and a focused beam of light to 
																visualize the structures at the front of the eye, including the cornea, conjunctiva, iris, lens, and anterior chamber.</p>
											<h4 className="title mb-10 mt-20">Key uses and benefits of a slit lamp bio-microscope:</h4>
										</div>
										<div className="row align-items-center">
											<div className="col-md-12 mb-30">
												<ul className="list-check-squer mb-0">
													<li>Comprehensive Eye Examination</li>
													<li>Detailed Evaluation of the Cornea</li>
													<li>Assessment of the Anterior Chamber</li>
													<li>Detection of Iris Abnormalities</li>
													<li>Visualization of the Lens</li>
													<li>Assessment of the Conjunctiva and Sclera</li>
													<li>Contact Lens Fitting</li>
													<li>Monitoring Progress of Eye Conditions</li>
													<li>Photodocumentation</li>
													<li>Non-invasive</li>
												</ul>
											</div>											
										</div>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Comprehensive Eye Examination</h4>
											<p className="nse-paragraph mb-0">Slit lamps are a fundamental tool in comprehensive eye examinations, allowing eye care specialists to thoroughly assess the health and condition of the anterior eye structures. This is essential for diagnosing various eye conditions and diseases.</p>
											
											<h4 className="title mb-10 mt-10">Detailed Evaluation of the Cornea</h4>
											<p className="nse-paragraph mb-0">The slit lamp's high magnification and focused illumination make it ideal for examining the cornea. This is crucial for diagnosing conditions like corneal abrasions, ulcers, dystrophies, and infections.</p>

											<h4 className="title mb-10 mt-10">Assessment of the Anterior Chamber</h4>
											<p className="nse-paragraph mb-0">The anterior chamber, the space between the cornea and the iris, is assessed for signs of inflammation, infection, or glaucoma. Measuring the depth of the anterior chamber can be important in diagnosing certain types of glaucoma.</p>

											<h4 className="title mb-10 mt-10">Detection of Iris Abnormalities</h4>
											<p className="nse-paragraph mb-0">Slit lamps enable the examination of the iris for structural anomalies, signs of inflammation, or conditions such as uveitis. The reaction of the pupil to light is also observed.</p>

											<h4 className="title mb-10 mt-10">Visualization of the Lens</h4>
											<p className="nse-paragraph mb-0">Cataracts, which are opacities in the eye's lens, can be readily observed using a slit lamp. The instrument helps determine the location and extent of lens opacities.</p>

											<h4 className="title mb-10 mt-10">Assessment of the Conjunctiva and Sclera</h4>
											<p className="nse-paragraph mb-0">The slit lamp aids in identifying any abnormalities or growths on the conjunctiva or sclera, such as conjunctivitis, pterygium, or foreign bodies.</p>

											<h4 className="title mb-10 mt-10">Contact Lens Fitting</h4>
											<p className="nse-paragraph mb-0">Slit lamps are essential for fitting and assessing contact lenses. They enable eye care practitioners to evaluate the fit of contact lenses on the eye and ensure they are not causing any irritation or complications.</p>

											<h4 className="title mb-10 mt-10">Monitoring Progress of Eye Conditions</h4>
											<p className="nse-paragraph mb-0"> Slit lamps are used to track the progression and treatment response of various eye conditions, such as dry eye syndrome, corneal diseases, and glaucoma.</p>

											<h4 className="title mb-10 mt-10">Photodocumentation</h4>
											<p className="nse-paragraph mb-0">Slit lamps often have the capability to capture high-resolution images and videos, allowing for accurate documentation of eye conditions and disease progression. This is particularly useful for patient records and consultations.</p>
											
											<h4 className="title mb-10 mt-10">Non-invasive</h4>
											<p className="nse-paragraph mb-0">The examination performed with a slit lamp is non-invasive and well-tolerated by patients, making it an essential tool for routine eye care</p>

											<p className="nse-paragraph mb-0 mt-30">The slit lamp biomicroscope provides exceptional detail, allowing eye care specialists to make precise diagnoses and provide appropriate treatments. Its versatility and ability to provide real-time imaging contribute to the instrument's effectiveness in eye care. Regular eye examinations with a slit lamp help maintain eye health and early detection of eye conditions, ensuring timely intervention and treatment when necessary.</p>
										</div>										
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li className="active"><Link to="/biomicroscopy-tests"><span>Bio Microscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/cornealtopography-tests"><span>Corneal Topography</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/dryeye-tests"><span>Dry Eye Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/fundoscopy-tests"><span>Fundoscopy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/keratometry-tests"><span>Keratometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/meibomianglandassessment-tests"><span>Meibomian gland Assessment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/ocularbiometry-tests"><span>Ocular Biometry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/opticalcoherencetomography-tests"><span>Optical Coherence Tomography (OCT)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/pachymetry-tests"><span>Pachymetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/perimetry-tests"><span>Perimetry</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/refraction-tests"><span>Refraction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/sensorymotor-tests"><span>Sensory Motor Test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/synoptophore-tests"><span>Synoptophore test</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/tonometry-tests"><span>Tonometry</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default BioMicroscopyDetail;