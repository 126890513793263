import React, { Component } from 'react';
import { Link } from 'react-router-dom';


// Import Images
import bg1 from '../../images/background/line-bg1.png';
import ptImg1 from '../../images/shap/008-ishihara-test.png';
import ptImg2 from '../../images/shap/004-sunglasses.png';
import ptImg3 from '../../images/shap/000-Blank.png';

class aboutSection extends Component{
	render(){
		return(
			<>
				
				<section className="section-area section-sp5 work-area" style={{backgroundImage: "url("+bg1+")", backgroundRepeat:" no-repeat", backgroundPosition: "center", backgroundSize: "100%"}}>
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext text-primary">Lets get intouch</h6>
							<h2 className="title">Schedule an Appointment with us</h2>
						</div>
						<div className="row justify-content-center">
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">01</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Book an Appointment</h5>
										<p>Securing your appointment is easy! Schedule your visit at any of our clinics.</p>
									</div>
									<Link to="/booking" className="btn btn-primary ">Book Now <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx active">
									<div className="work-num-bx">02</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Eye Examination Packages</h5>
										<p>Know more about our comprehensive eye examination packages and more of our services.</p>
									</div>
									<Link to="/services" className="btn btn-primary">Learn More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">03</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Our Team</h5>
										<p>Get to know our amazing team of doctors</p>
									</div>
									<Link to="/team" className="btn btn-primary">Learn More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">04</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Corporate Eye Care Program</h5>
										<p>We'd love to partner with you in making sure your employees' vision is at it's best.</p>
									</div>
									<Link to="/corporateeyeexam" className="btn btn-primary">Learn More <i className="btn-icon-bx fas fa-chevron-right"></i></Link>
								</div>
							</div>
						</div>
					</div>
					<img className="pt-img1 animate1" src={ptImg3} alt=""/>
					<img className="pt-img2 animate2" src={ptImg3} alt=""/>
					<img className="pt-img3 animate3" src={ptImg3} alt=""/>
				</section>
				
			</>
		);
	}
}

export default aboutSection;