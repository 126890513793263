import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import Downloadables from '../../elements/downloadables';

class AccommodativeVergenceDysfunction extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
								<h3>Common Eye & Vision Conditions</h3>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/accommodativevergencedysfunction">Common Eye & Vision Conditions</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Accommodative Vergence Dysfunction</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Accommodative Vergence Dysfunction</h2>
											<p className="nse-paragraph mb-0">Often referred to as accommodative convergence excess (ACE) or accommodative esotropia, is a vision disorder characterized by an imbalance between the eyes' focusing (accommodation) and converging (vergence) mechanisms. This condition typically occurs when the accommodative system overcompensates during near vision tasks, leading to excessive convergence of the eyes and resulting in various symptoms and visual issues. Here are some key characteristics and symptoms associated with accommodative vergence dysfunction</p>											
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h4 className="title mb-10 mt-10">Excessive Convergence</h4>
											<p className="nse-paragraph mb=1">During close-up tasks like reading, the eyes naturally converge to focus on the near object. In accommodative vergence dysfunction, the eyes converge more than necessary, leading to an esotropia, which is an inward turning of one or both eyes</p>

											<h4 className="title mb-10 mt-10">Symptoms</h4>
											<p className="nse-paragraph mb=1">Common symptoms of accommodative vergence dysfunction may include eye strain, double vision (diplopia), headaches, and difficulty with sustained near tasks like reading or using electronic devices. Age of Onset: Accommodative vergence dysfunction often appears in childhood, and it can persist into adulthood if not properly managed.</p>
											
											<h4 className="title mb-10 mt-10">Causes</h4>
											<p className="nse-paragraph mb=1">The exact cause of this condition can vary, but it is often associated with an imbalance between the accommodative and vergence systems. Factors that contribute to this imbalance can include a high accommodative convergence to accommodation (AC/A) ratio, uncorrected hyperopia (farsightedness), or other visual anomalies.</p>
											
											<h4 className="title mb-10 mt-10">Diagnosis</h4>
											<p className="nse-paragraph mb=1">Diagnosis is typically made through a comprehensive eye examination by an optometrist or ophthalmologist. Testing may include assessing near point of convergence (NPC), assessing the AC/A ratio, and determining the refractive error.</p>
											
											<h4 className="title mb-10 mt-10">Treatment</h4>
											<p className="nse-paragraph mb=1">Management of accommodative vergence dysfunction may involve prescription glasses or contact lenses to correct any refractive errors, such as hyperopia. Vision therapy, which includes exercises and activities to improve eye coordination and focusing, is often a primary treatment option. In some cases, prismatic lenses or prisms incorporated into glasses may be used to alleviate symptoms and encourage more balanced convergence.</p>
											

											<p className="nse-paragraph mb-0 mt-30">It's important to seek professional care from an eye care specialist if you or your child experiences symptoms of accommodative vergence dysfunction. With the appropriate diagnosis and treatment, this condition can often be managed effectively, reducing symptoms and improving visual comfort during near tasks.</p>

										</div>
										<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Question 1</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 1</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Question 2</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 2</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li className='active'><Link to="/accommodativevergencedysfunction"><span>Accommodative Vergence Dysfunction</span><i className="fa fa-angle-right"></i></Link></li>												
												<li><Link to="/amblyopia"><span>Amblyopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/astigmatism"><span>Astigmatism</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Blepharitis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Cataracts</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Colour Blindness</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Conjunctivitis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Convergence Insufficiency</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Diabetic Retinopathy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Diplopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Dry Eyes or Watery Eyes</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Emmetropia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Floaters</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Glaucoma</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Hordeolum (Stye)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Hypermetropia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Iritis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Keratoconus</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Keratitis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Macular Degeneration</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Maculopathy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Meibomian gland Dysfunction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Myopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Pinguecula</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Presbyopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Ptosis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Pterygium</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Retinal Detachment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Scleritis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Strabismus</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Superficial Punctate Keratitis (SPK) </span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Trichiasis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Traumatic Brain Injury (TBI)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Uveitis</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default AccommodativeVergenceDysfunction;