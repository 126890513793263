import React, {Component, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import BookingAPIService from '../../api/BookingService';

const BookingStatus = () =>{
	
	const [bookingData,setBookingData] = useState({});
	const params = useParams();
	const apiService = new BookingAPIService();

	const fetchBookingStatus = async () =>{
		const resp = await apiService.getStatus(params?.bookingid);
		if(resp?.status >= 200){
			setBookingData(resp.data);
		}
	};

	useEffect(()=>{     
        fetchBookingStatus();
    },[]);
	
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h2>Booking Status</h2>
									
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
				
					<section className="section-area section-sp2 appointment-wraper">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-xl-9 col-lg-9 col-md-12">
								<div className="appointment-form">
									<h3 className='title'>Booking Record</h3>
									<div className="container">
										<div className="row">
											<div className="col-lg-12 col-sm-12 mb-30 m-30">
											<table className="table" style={{textAlign:'left'}}>											
												<tbody>
													<tr>
													<th scope="row">Date</th>
													<td>{bookingData?.bookingdate}</td>
													</tr>
													<tr>
													<th scope="row">Time</th>
													<td>{bookingData?.bookingtime}</td>
													</tr>
													<tr>
													<th scope="row">Branch</th>
													<td>{bookingData?.branch}</td>
													</tr>
													<tr>
													<th scope="row">Patient</th>
													<td>{bookingData?.patientname}</td>
													</tr>
													<tr>
													<th scope="row">Email</th>
													<td>{bookingData?.email}</td>
													</tr>
													<tr>
													<th scope="row">Mobile Number</th>
													<td>{bookingData?.mobile}</td>
													</tr>
													<tr>
													<th scope="row">Status</th>
													<td style={{color: (bookingData?.status === 'Booked')? "green":'red'}}>{bookingData?.status}</td>
													</tr>
													<tr>
													<th scope="row">Remarks</th>
													<td>{bookingData?.remarks}</td>
													</tr>
												</tbody>
											</table>
											</div>
										</div>	
									</div>
								</div>				
								</div>
							</div>					
						</div>
					</section>
					
				</div>
			
			</>
		);
	}


export default BookingStatus;