import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/noimage.jpg";
import Downloadables from '../../elements/downloadables';

class Astigmatism extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h3>Common Eye & Vision Conditions</h3>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item"><Link to="/accommodativevergencedysfunction">Common Eye & Vision Conditions</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Astigmatism</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 mb-30">
									<div className="ttr-media mb-30">
										<img src={servicesPic1} className="rounded" alt=""/>
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Astigmatism</h2>
											<p className="nse-paragraph mb-0">Is a common refractive error of the eye that results in blurred or distorted vision. It occurs when the cornea (the clear, front surface of the eye) or, less commonly, the lens inside the eye has an irregular shape rather than being perfectly spherical. This irregular shape causes light entering the eye to be focused unevenly on the retina, leading to vision problems.</p>											
										</div>										
									</div>
									<div className="clearfix">
										<div className="head-text mb-30">
										<	div className='heading-bx'>
												<h6 className="title-ext text-primary">Key characteristics of astigmatism include</h6>
											</div>

											<h4 className="title mb-15">Blurry or Distorted Vision</h4>
											<p className="nse-paragraph mb-1">Individuals with astigmatism often experience blurred or distorted vision at various distances. They may have difficulty seeing fine details, both up close and in the distance.</p>			

											<h4 className="title mb-15">Common Comorbidity</h4>
											<p className="nse-paragraph mb-1">Astigmatism can occur alongside other refractive errors, such as myopia (nearsightedness) and hyperopia (farsightedness), and it's often addressed in eyeglass or contact lens prescriptions alongside these other conditions.</p>									

											<h4 className="title mb-10 mt-10">Types</h4>
											<div className="ml-30">	
												<ul>
													<li className='nse-list-group-item'>Corneal Astigmatism: This is the most common type and occurs when the cornea has an irregular shape.</li>
													<li className='nse-list-group-item'>Lenticular Astigmatism: This type is caused by irregularities in the eye's natural lens, located behind the cornea</li>
												</ul>
											</div>

											<h4 className="title mb-15">Axis</h4>
											<p className="nse-paragraph mb-1">In an astigmatism prescription, the axis is noted to indicate the orientation of the irregularity. The axis is measured in degrees, typically ranging from 0 to 180 degrees, with 90 degrees representing the vertical meridian and 180 degrees representing the horizontal meridian.</p>	

											<h4 className="title mb-15">Treatment</h4>
											<p className="nse-paragraph mb-1">Astigmatism is commonly corrected with eyeglasses or contact lenses, which compensate for the uneven focusing of light. Refractive surgery, such as LASIK or PRK, can also be used to reshape the cornea and correct astigmatism.</p>			
											

											<p className="nse-paragraph mb-0 mt-30">It's important to note that astigmatism is a very common vision condition, and most people have some degree of astigmatism. The severity of astigmatism can vary from mild to severe, and many individuals may not even be aware that they have it until they undergo an eye examination. Regular eye exams with an optometrist or ophthalmologist are essential for diagnosing and correcting astigmatism and other vision issues to ensure clear and comfortable vision.</p>

										</div>
										<Accordion defaultActiveKey="0" className="accordion ttr-accordion1">
											<Accordion.Item eventKey="0">
												<Accordion.Header>Question 1</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 1</p>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Question 2</Accordion.Header>
												<Accordion.Body>
													<p className="mb-0">Answer 2</p>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</div>
								</div>
								<div className="col-lg-4">
									<aside className="sticky-top pb-1">
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/accommodativevergencedysfunction"><span>Accommodative Vergence Dysfunction</span><i className="fa fa-angle-right"></i></Link></li>												
												<li><Link to="/amblyopia"><span>Amblyopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li className='active'><Link to="/astigmatism"><span>Astigmatism</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Blepharitis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Cataracts</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Colour Blindness</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Conjunctivitis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Convergence Insufficiency</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Diabetic Retinopathy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Diplopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Dry Eyes or Watery Eyes</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Emmetropia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Floaters</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Glaucoma</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Hordeolum (Stye)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Hypermetropia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Iritis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Keratoconus</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Keratitis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Macular Degeneration</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Maculopathy</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Meibomian gland Dysfunction</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Myopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Pinguecula</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Presbyopia</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Ptosis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Pterygium</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Retinal Detachment</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Scleritis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Strabismus</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Superficial Punctate Keratitis (SPK) </span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Trichiasis</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Traumatic Brain Injury (TBI)</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/accommodativevergencedysfunction"><span>Uveitis</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										<Downloadables/>
										
									</aside>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default Astigmatism;