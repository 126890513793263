import React, { Component } from 'react';

// Import Images
import teamMember1 from '../../images/team/doc1.jpg';
import teamMember2 from '../../images/team/doc2.jpg';
import teamMember3 from '../../images/team/doc3.jpg';
import teamMember4 from '../../images/team/doc4.jpg';
import teamMember5 from '../../images/team/doc5.jpg';
import teamMember6 from '../../images/team/doc6.jpg';
import ptImg1 from '../../images/shap/trangle-orange.png';
import ptImg2 from '../../images/shap/square-dots-orange.png';
import ptImg3 from '../../images/shap/line-circle-blue.png';
import ptImg4 from '../../images/shap/wave-blue.png';
import ptImg5 from '../../images/shap/circle-dots.png';

const teamMembers = [
	{
		name: 'Dr. John Nakpil',
		specialization: 'Neuro Rehabilitation Optometrist',
		image: teamMember1,
		socialMedia: [
			{ platform: 'Twitter', link: 'https://twitter.com/' },
			{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
			{ platform: 'Instagram', link: 'https://www.instagram.com/' },
		],
	},
	{
		name: 'Dr. Kim Lorenzo',
		specialization: 'Ophthalmologist',
		image: teamMember2,
		socialMedia: [
			{ platform: 'Twitter', link: 'https://twitter.com/' },
			{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
			{ platform: 'Instagram', link: 'https://www.instagram.com/' },
		],
	},
	/*
	{
		name: 'Dr. Zexter Cafe',
		specialization: 'Optometrist / Registered Nurse',
		image: teamMember3,
		socialMedia: [
			{ platform: 'Twitter', link: 'https://twitter.com/' },
			{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
			{ platform: 'Instagram', link: 'https://www.instagram.com/' },
		],
	},
	*/
	{
		name: 'Dr. Kathryna Gregorio',
		specialization: 'Optometrist',
		image: teamMember4,
		socialMedia: [
			{ platform: 'Twitter', link: 'https://twitter.com/' },
			{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
			{ platform: 'Instagram', link: 'https://www.instagram.com/' },
		],
	},
	{
		name: 'Dr. Jhe-Ann Negparanon',
		specialization: 'Optometrist',
		image: teamMember5,
		socialMedia: [
			{ platform: 'Twitter', link: 'https://twitter.com/' },
			{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
			{ platform: 'Instagram', link: 'https://www.instagram.com/' },
		],
	},
	{
		name: 'Dr. Jonathan Fajardo',
		specialization: 'Optometrist',
		image: teamMember6,
		socialMedia: [
			{ platform: 'Twitter', link: 'https://twitter.com/' },
			{ platform: 'LinkedIn', link: 'https://www.linkedin.com/' },
			{ platform: 'Instagram', link: 'https://www.instagram.com/' },
		],
	},
];

class TeamSection extends Component{
	render(){
		return(
			<>
				
				<section className="section-area section-sp3 team-wraper">
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext text-secondary">Our Doctor</h6>
							<h2 className="title">Meet Best Doctors</h2>
						</div>
						<div className="row justify-content-center">
							{teamMembers.map((teamMember, index) => (
								<div key={index} className="col-lg-4 col-sm-6 mb-30">
									<div className="team-member">
										<div className="team-media">
											<img src={teamMember.image} alt={teamMember.name} />
										</div>
										<div className="team-info">
											<div className="team-info-content">
												<h4 className="title">{teamMember.name}</h4>
												<span className="text-secondary">{teamMember.specialization}</span>
											</div>
											<ul className="social-media mt-3">
												{teamMember.socialMedia.map((social, index) => (
													<li key={index}>
														<a rel="noreferrer" target="_blank" href={social.link}>
															<i className={`fab fa-${social.platform.toLowerCase()}`} />
														</a>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<img className="pt-img1 animate1" src={ptImg1} alt=""/>
					<img className="pt-img2 animate2" src={ptImg2} alt=""/>
					{/*<img className="pt-img3 animate-rotate" src={ptImg3} alt=""/>*/}
					<img className="pt-img4 animate-wave" src={ptImg4} alt=""/>
					<img className="pt-img5 animate-wave" src={ptImg5} alt=""/>
				</section>
				
			</>
		);
	}
}

export default TeamSection;